import axios from 'axios';
import { Constants } from './Constants';

(async () => {
  const rootUrl =
    location.hostname === 'localhost' ? 'http://localhost:8080' : `${location.protocol}//${location.host}`;
  const response = await axios.get(`${rootUrl}/config.json`);
  const config = response.data;
  Constants.init(config, rootUrl);
  import('@/app');
})();
