import { IAuth0Config, IConfig } from './contracts/config';

export class Constants {
  public static ROOT_URL: string;
  public static USER_SERVICE_ROOT_URL: string;
  public static EXPORT_PDF_ROOT_URL: string;
  public static AUTH0_CONFIG: IAuth0Config;

  public static init(config: IConfig, rootUrl: string): void {
    this.ROOT_URL = `${rootUrl}`;
    this.AUTH0_CONFIG = config.auth0;
    this.USER_SERVICE_ROOT_URL = config.userServiceRootUrl;
    this.EXPORT_PDF_ROOT_URL = config.exportPdfRootUrl;
  }

  public static get validationConstants(): IValidationConstants {
    return {
      maxPasswordLength: 200,
      maxUsernameLength: 200,
      maxActivityNameLength: 100,
      maxActivityDescriptionLength: 1000,
      maxLearningTrackNameLength: 100,
      maxLearningTrackDescriptionLength: 1000,
      maxLearningMaterialPulicationNameLength: 50,
      maxLearningTrackStartTextLength: 1500,
      maxLearningTrackEndTextLength: 1500,
    };
  }

  public static get ITEMS_TO_LOAD_IN_INFINITE_SCROLL() {
    return 27;
  }
}

export interface IValidationConstants {
  maxUsernameLength: number;
  maxPasswordLength: number;
  maxActivityNameLength: number;
  maxActivityDescriptionLength: number;
  maxLearningTrackNameLength: number;
  maxLearningTrackDescriptionLength: number;
  maxLearningMaterialPulicationNameLength: number;
  maxLearningTrackStartTextLength: number;
  maxLearningTrackEndTextLength: number;
}
